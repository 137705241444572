import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Custom Software",
      description: "Crafting bespoke software solutions tailored to meet your unique business needs, ensuring optimal functionality and efficiency.",
      imgUrl: '',
    },
    {
      title: "Data & Analytics",
      description: "Turning raw data into meaningful insights to drive decision-making, increase operational efficiency, and shape future strategies.",
      imgUrl: '',
    },
    {
      title: "Experience Design",
      description: "Creating intuitive, user-friendly designs that enhance customer engagement and drive user satisfaction across all touchpoints.",
      imgUrl: '',
    },
    {
      title: "Strategy & Advisory",
      description: "Providing strategic advice rooted in deep industry knowledge, to help you navigate your digital journey and reach your business goals.",
      imgUrl: '',
    },
    {
      title: "Digital Transformation",
      description: "Leveraging technology to transform business processes, culture, and customer experiences, enabling you to thrive in the digital age.",
      imgUrl: '',
    },
    {
      title: "Business Startup",
      description: "Offering comprehensive support to startups, from initial concept to market launch, helping to turn your innovative ideas into successful businesses.",
      imgUrl: '',
    },
  ];

  return (
    <section className="project" id="aboutus">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>About Us</h2>
                <p>Welcome to Nusoft, the next frontier in digital innovation. We are a powerhouse with expertise in the latest technology stack. Our streamlined solutions are crafted to push the boundaries of today's tech landscape, promoting a future where data security, connectivity, and user agency take center stage. At Nusoft, we are not just keeping pace with technology, we are defining its course. Trust us to turn your digital dreams into reality.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Our Company</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Chat with us</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">What we do</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <p>
                        At Nusoft, we don't just develop software. We craft the kind of comprehensive digital solutions that propel businesses into the future. Based in vibrant Cape Town and serving an extensive global clientele, our expertise spans a wide spectrum - from full stack web development, through blockchain technologies, to mobile apps and beyond.
                        <br/><br/>
                        Nusoft is more than just a software company. We are a diverse collective of creative engineers and problem solvers, united by a shared passion for technology and innovation. Our team works relentlessly to create cutting-edge solutions that simplify complex business challenges and generate real value.
                        <br/><br/>
                        Our specialization in blockchain and smart contracts allows us to provide secure and decentralized solutions, revolutionizing the way businesses operate and opening up exciting new possibilities. With a customer-centric approach, we offer personalized services tailored to your unique needs, ensuring that every solution we provide aligns perfectly with your vision and business goals.
                        <br/><br/>
                        Being a global player, we embrace the diversity of our clientele, and our experienced global team is adept at understanding and navigating the nuances of different markets and industries. This global perspective enables us to bring a wealth of fresh ideas to every project.
                        <br/><br/>
                        Yet, despite our extensive capabilities and global reach, we maintain an approachable, easy-to-work-with culture. At Nusoft, we believe that the foundation of successful partnerships is open communication and transparency. We're here to listen, understand, and deliver solutions that exceed your expectations.
                        Welcome to Nusoft. Together, let's shape the future of technology.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                    <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}